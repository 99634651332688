












import { Component, Mixins } from 'vue-property-decorator'
import { VueConstructor } from 'vue'

import { StructureConfigurable } from '../../../../../support/mixins'

import { SimpleFormMixin } from '../../../mixins/simpleForm.mixin'

import {
  SIMPLE_FORM_COMPONENT_CONFIG_MAP,
  SIMPLE_FORM_COMPONENT_KEY, simpleFormComponentsRegistry,
  SimpleFormConfig
} from './SimpleForm.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<SimpleForm>({
  name: 'SimpleForm',
  created () {
    this.config = this.getComponentConfig(SIMPLE_FORM_COMPONENT_KEY, { ...SIMPLE_FORM_COMPONENT_CONFIG_MAP })
  }
})
export class SimpleForm extends Mixins(SimpleFormMixin, StructureConfigurable) {
  protected config!: SimpleFormConfig

  public get component (): VueConstructor {
    const layout = this.getConfigProperty<string>('layout')
    return simpleFormComponentsRegistry[layout]
  }
}

export default SimpleForm
