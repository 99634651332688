






















import { Component, Prop } from 'vue-property-decorator'
import { SimpleFormMixin } from '../../../../mixins/simpleForm.mixin'
import { SimpleFormProps } from '../SimpleForm.contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<Toggleable>({
  name: 'Toggleable'
})
export class Toggleable extends SimpleFormMixin {
  @Prop({ type: Boolean, required: false, default: true })
  public readonly dirtyMode!: boolean

  @Prop({ type: String, required: false })
  public readonly editLabel?: SimpleFormProps['editLabel']

  @Prop({ type: String, required: true })
  public readonly heading!: SimpleFormProps['heading']

  public isDirty: boolean = !this.dirtyMode
  public isEditing: boolean = false

  /**
   * Determines whether label is given.
   */
  public get hasLabel (): boolean {
    return typeof this.editLabel !== 'undefined' && this.editLabel.length > 0
  }

  public get toggleLabel (): string {
    const translation: string = !this.isEditing
      ? (this.hasLabel ? String(this.editLabel) : 'front.shared.simpleForm.editLabel')
      : 'front.shared.simpleForm.cancelLabel'

    return this.$t(translation).toString()
  }

  public markAsDirty (): void {
    if (!this.isEditing) {
      return
    }

    this.isDirty = true
  }

  /**
   * Toggles editing state.
   */
  public toggleEdit (): void {
    this.isEditing = !this.isEditing
    this.$emit('beginEditing')
  }
}

export default Toggleable
